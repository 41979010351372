import videojs from 'video.js'
import 'videojs-youtube';
import {Header} from "./modules/Header";
import {Corporate} from "./modules/Corporate";
import {FactsFigures} from "./modules/FactsFigures";
import {Leadership} from "./modules/Leadership";
import {tabs} from './components/tabs';
import {customSelect} from './components/customSelect';
import {header} from './components/header';
import {popup} from './components/popup';
import {slider} from './components/slider';
import { cookiePolicy } from './components/cookie';
import 'slick-carousel';
import $ from 'jquery';
import { filterTabs } from './components/filterTabs';
import { pagination } from './components/pagination';
import { rangeSlider } from './components/range-slider';
import { authForm } from './components/auth-form';
import { searchForm } from './components/search-form';
import { getParamsForFilter } from './components/getParamsForFilter';
import { disclaimer } from './components/disclaimer';

window.$ = $;
window.jQuery = $;
window.videoJs = videojs;
window.getParam = (name) => {
    return location.search.split(`${name}=`).splice(1).join('').split('&')[0];
}

new Header()

if(document.querySelector('.filter') && window.innerWidth < 1200) {
    filterTabs()
    // window.addEventListener('resize', (event) => {
    //     filterTabs()
    // });
}



if(document.querySelector('#corporateResponsibility')) new Corporate()

if(document.querySelector('#factsFigures')) new FactsFigures()

if(document.querySelector('#leadership')) new Leadership()

document.addEventListener('DOMContentLoaded', () => {
	const play = document.querySelector('.pages-main-screen__play');
    const togglePassword = document.querySelectorAll('._js-toggle-password');

	$('.video-js').each((index, item) => {
		const $video = $(item);
		const $parent = $video.parent();

		let player = $parent.data('player');
		player = videoJs(item, {
			controls: true,
			playsinline: true,
			// fluid: true,
			muted: false,
			html5: {
				nativeAudioTracks: false,
				nativeVideoTracks: false,
				nativeTextTracks: false,
			},
			hls: {
				overrideNative: true,
			},
		});

		$parent.data('player', player);
	});
    cookiePolicy();
	tabs();
	popup();
	header();
	slider();

    if(document.querySelector('.js-range-slider')) rangeSlider();

    if (togglePassword) {
        togglePassword.forEach(element => {
            element.addEventListener('click', (event) => {
                const target = event.currentTarget;
                const label = target.closest('label');
                const input = label.querySelector('._js-password');
                console.log(target);
                console.log(input);
                console.log(input.type);
                if (input.type === "password") {
                    input.type = "text";
                    label.classList.add('show')
                  } else {
                    input.type = "password";
                    label.classList.remove('show')
                  }
            })
        });
    }


    const input = document.querySelectorAll('._js-input');
    const toggleInput = (event) =>{
        const type = event.type;
        const target= event.currentTarget;
        const label = target.closest('label');

        if (type === 'focus') {
            label.classList.add('focus')
        }

        if (type === 'blur') {
            label.classList.remove('focus');
        }

        if (target.matches(':-webkit-autofill')) {
            label.classList.add('focus');
        }

        if (target.value != '') {
            label.classList.add('focus');
        }
    }



    input.forEach(element => {
        element.addEventListener('focus', toggleInput)
        element.addEventListener('blur', toggleInput)
    });


    authForm();

    if(document.querySelector('.medical-library__container._is-auth')) {
        searchForm();
        pagination();
        getParamsForFilter();
    }

    customSelect();


	if (play) {
		play.addEventListener('click', () => {
			$('#video').data('player').play();
		})
	}
    disclaimer()
});


