/* eslint-disable no-undef */
const customSelect = () => {
	const select = document.querySelectorAll('.custom-select');
    // console.log(select);

	if (!select) {
		return false;
	}

    select.forEach(element => {
        NiceSelect.bind(element);
    });
};

export {customSelect};
