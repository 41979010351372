const pagination = () => {
    const page = document.querySelector('input[name="page"]');
    const paramPage = getParam('page');
    const currentPage = paramPage ? paramPage : 1;
    const prevText = currentPage > 1 && 'Назад';
    const nextText = currentPage < lastPage && 'Далее';
    const itemsOnPage = perPage;


    $('#pagination').pagination({
        items: total,
        itemsOnPage,
        currentPage,
        edges: 1,
        hrefTextPrefix: '',
        hrefTextSuffix: '',
        ellipsePageSet: false,
        displayedPages: 3, //  Сколько номеров страниц должно быть видно во время навигации. Минимально допустимое значение: 3, по умолчанию: 5.
        prevText,
        nextText,
        onPageClick: function (pageNumber, event) {
            event.preventDefault();
            page.value =pageNumber;
            submitFilters();
        },
        // onInit: function () {
        //     console.log('onInit');
        // }
    })

    //     $('#pagination').pagination({
    //     items: 12,
    //     itemsOnPage: 1,
    //     // currentPage: data.page,
    //     edges: 1,
    //     hrefTextPrefix: '',
    //     hrefTextSuffix: '',
    //     ellipsePageSet: false,
    //     displayedPages: 2, //  Сколько номеров страниц должно быть видно во время навигации. Минимально допустимое значение: 3, по умолчанию: 5.
    //     prevText: 'Предыдущая',
    //     nextText: 'Следующая',
    //     onPageClick: function (pageNumber, event) {
    //         // console.log(pageNumber);
    //         event.preventDefault();
    //     },
    //     onInit: function (pageNumber, event) {
    //         console.log('onInit');
    //     }
    // })
}

export {pagination}
