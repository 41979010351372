const authForm = () => {
    let isSubmit = true;
    const form = document.querySelector('.medical-library__form');
    if(!form) return;
    const checkbox = document.querySelectorAll('input[type="checkbox"]');
    const inpt = form.querySelectorAll('input[name="email"], input[type="password"], input[name="toemail"]');
    const submit = form.querySelector('.medical-library__button');

    let cntLogin = 0;
    let cntPassword = 0;

    checkbox.forEach(element => {
        element.addEventListener('change', (event) => {
            const target = event.target;
            const parent = target.closest('label');
            if (target.checked) {
                parent.classList.remove('_error');
                isSubmit = true;
            }
        })
    });

    const setCnt = (event, cnt) => {
        const target = event.target;
        const length = target.value.length;
        return length;
    }

    const checkActiveBtn = () => {
        if (cntLogin > 0 && cntPassword > 0) {
            submit.classList.remove('_no-active');
        } else {
            submit.classList.add('_no-active');
        }
    }

    const chengehandler = (event) => {
        const target = event.target;
        switch (target.name) {
            case 'password':
                cntPassword = setCnt(event);
                break;
            case 'email':
                cntLogin = setCnt(event);
                break;
            default:
                break;
        }

        checkActiveBtn();
    }


    inpt.forEach(element => {
        element.addEventListener('change', chengehandler);
    });


    if (form) {

        form.addEventListener('submit', (event, cntPassword) => {
            const rules = event.target.querySelector('input[name="rules-agreed"]');

            event.preventDefault();

            checkbox.forEach(element => {
                if (element.checked) {
                    element.checked="true";
                    element.value= true;
                } else {
                    element.value= false;
                }

            });

            if (!rules.checked) {
                const parent = rules.closest('label');
                parent.classList.add('_error');
                isSubmit = false;

            }

            if (isSubmit) form.submit();
        })
    }

    window.onload = () => {
        const autofill = form.querySelectorAll('input:-webkit-autofill');
        if (autofill.length > 1) submit.classList.remove('_no-active');

        inpt.forEach(element => {
            element.dispatchEvent(new Event('focus'));
        });

    }


}

export {authForm}

