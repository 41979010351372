import ionRangeSlider from 'ion-rangeslider';

const rangeSlider = () => {
    const inputFrom = document.querySelector('#min');
    const inputTo = document.querySelector('#max');

    const minParam = getParam('from');
    const maxParam = getParam('to');

    const from = minParam ? minParam : 1990;
    const to = maxParam ? maxParam : actualYear;

    // const minInput = document.querySelector('.search-form input[name="from"]');
    // const maxInpt = document.querySelector('.search-form input[name="to"]');
    const actualYear = new Date().getFullYear();

    let min = String(Math.round($(inputFrom).prop('value')));
    let max = String(Math.round($(inputTo).prop('value')));

    const updateInputs = (data) => {
        $(inputFrom).prop("value", (data.from));
        $(inputTo).prop("value", (data.to));

        // $(minInput).prop("value", (data.from));
        // $(maxInpt).prop("value", (data.to));
    }

    $(".js-range-slider").ionRangeSlider({
        skin: "round",
        type: "double",
        min: 1990,
        max: actualYear,
        from,
        to,
        hide_min_max: true,
        hide_from_to: true,
        onStart: updateInputs,
        onChange: updateInputs,
        onFinish: () => {
            // console.log('onFinish');
        }
    });

    const instance = $(".js-range-slider").data('ionRangeSlider');
    instance.update({min, max, from, to});

    inputFrom.addEventListener('change', (event) => {
        const target = event.target;
        let val = target.value;

        if (+min > +val) {
            val = min;
        }

        if (+val > +max) {
            val = max;
        }

        $(target).prop("value", val);

        // minInput.value = val

        instance.update({
            from: val
        });
    })

    inputTo.addEventListener('change', (event) => {
        const target = event.target;
        let val = target.value;

        if (+val > +max) {
            val = max;
        }

        if (+val < +min) {
            val = min;
        }

        $(target).prop("value", val);

        // maxInpt.value = val;

        instance.update({
            to: val
        });
    })

}

export {rangeSlider}
