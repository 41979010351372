export class Header {
    constructor() {

        this.init()
    }

    init() {
        this.removeClass()
        this.toggleMobileMenu()
    }

    removeClass() {
        [...document.querySelectorAll('.navbar__submenu-container')].forEach(el => {
            el.classList.remove('_hidden')
        })
    }

    toggleMobileMenu() {
        const navbarItem = document.querySelectorAll('.navbar__item')
        const submenuItem = document.querySelectorAll('.navbar__sub-item')

        navbarItem.forEach(el => {
            const submenuContainer = el.querySelector('.navbar__submenu-container')

            // if (window.innerWidth < 1025) el.classList.add('_unHover') // for disabled hover effect

            // if (submenuContainer) {
            //     el.addEventListener('click', (e) => {
            //         if (e.target.classList.contains('navbar__link')) el.classList.toggle('_show')
            //     })
            // }
        })

        submenuItem.forEach(el => {
            const nestedMenu = el.querySelector('.navbar__nested-menu')

            // if (window.innerWidth < 1025) el.classList.add('_unHover') // for disabled hover effect

            // if (nestedMenu) {
            //     el.addEventListener('click', (e) => {
            //         if (e.target.dataset.openMenu === 'true') el.classList.toggle('_show')
            //     })
            // }
        })
    }

}
