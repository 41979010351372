/* eslint-disable no-undef */
const header = () => {
	const body = document.querySelector('body');
	const btn = document.querySelector('.header__search');
	const headerT = document.querySelector('.header');
	const search = document.querySelector('.search');
	if (!btn) {
		return false;
	}
	body.addEventListener('click', (event) => {
		const target = event.target;
		const hs = target.closest('.header__search');
		const s = target.closest('.search');

		if (hs && !hs.classList.contains('open') || s) {
			btn.classList.add('open');
			headerT.classList.add('open');
			search.classList.add('open');
		} else {
			btn.classList.remove('open');
			headerT.classList.remove('open');
			search.classList.remove('open');
		}
	});
};

export {header};
