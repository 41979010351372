export class FactsFigures {
    constructor() {
        this.init()
    }

    init() {
        this.onFadeEffect()
    }

    onFadeEffect() {
        [...document.querySelectorAll('.ff-card')].forEach(el => {
            const mainScreenBtn = el.querySelector('.ff-card__main .button')
            const secondScreenBtn = el.querySelector('.ff-card__second .button')
            const mainScreen = el.querySelector('.ff-card__main')
            const secondScreen = el.querySelector('.ff-card__second')

            // this.handleClick(mainScreenBtn, el, true)
            // this.handleClick(secondScreenBtn, el, false)

            // if (window.innerWidth < 576) {
                this.handleClick(mainScreen, el, true)
                this.handleClick(secondScreen, el, false)
            // }
        })
    }

    handleClick(btn, el, flag) {
        console.log('handleClick');
        btn.addEventListener('click', () => {
            if (flag) el.classList.add('_fade-in')
            else el.classList.remove('_fade-in')
        })
    }

}
