export class Leadership {
    constructor() {
        this.init()
    }

    init() {
        this.handleReadMore()
    }

    handleReadMore() {
        const appealWrap = document.querySelector('.leadership-inner__appeal-wrap')
        const appealContent = document.querySelector('.appeal__content')
        const expandBtn = document.querySelector('.appeal__expand')
        const collapseBtn = document.querySelector('.appeal__collapse')

        expandBtn.addEventListener('click', () => {
            appealWrap.classList.add('_show')
        })

        collapseBtn.addEventListener('click', () => {
            appealWrap.classList.remove('_show')
        })
    }
}
