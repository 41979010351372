export class Corporate {
    constructor() {
        this.container = document.querySelector('.corporate-culture__principles')

        this.switchPrinciples(this.container)
    }

    switchPrinciples(container) {
        const spheres = container.querySelectorAll('.principles__sphere')
        const descriptions = container.querySelectorAll('.principles-desc__wrap')

        spheres.forEach(el => {
            const inner = el.querySelector('.principles__sphere-inner')

            inner.addEventListener('click', () => {
                const href = inner.dataset.href

                this.iteration(spheres)
                this.iteration(descriptions)

                descriptions.forEach(item => {
                    const id = item.dataset.id

                    if (href === id) {
                        el.classList.add('_active')
                        item.classList.add('_active')
                    }
                })
            })
        })
    }

    iteration(arr) {
        for (const item of arr) item.classList.remove('_active')
    }
}
