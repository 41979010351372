/* eslint-disable no-undef */
const popup = () => {
  const popupVideo = document.querySelectorAll('.popup-video');

  popupVideo.forEach((element) => {
    let isPlaying = true;
    element.addEventListener('click', (event) => {
      const target = event.target;
      const vj = target.closest('.popup-video');

      if (vj) {
        element.classList.add('open');
        $(element).data('player').play();
      }
      if (target.closest('.popup-video__close') || target.closest('.popup-video__overlay')) {
        element.classList.remove('open');
        isPlaying = false;
        $(element).data('player').pause();
      }
    })
  });
};

export {popup};
