
const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

const cookiePolicy = () => {
    const name = 'cookie_policy'
    const cookie = document.querySelector('.cookie');

    if (!cookie) return false

    const btn = cookie.querySelectorAll('.cookie-btn');
    const isCookie = getCookie(name);


    const hideCookie = () => {

        cookie.remove();
    }

    const setCookie = (name) => {
        const date = new Date();

        date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 365));
        document.cookie = `${name}=${date}`;

        hideCookie();
    }

    const showCookie = () => {
        cookie.classList.remove('is-hidden')
        btn.forEach(element => {
            element.addEventListener('click', () => setCookie(name));
        });
    }

    if (isCookie) {
        hideCookie()
    } else {
        showCookie();
    }


}
export {cookiePolicy};
