const disclaimer = () => {
    const popup = document.querySelector('.disclaimer');
    if(popup) {
        const openDisclaimer = document.querySelectorAll('.js-open-disclaimer');
        if(openDisclaimer.length) {
            openDisclaimer.forEach(el => {
                el.addEventListener('click', function(e) {
                    e.preventDefault();
                    let href = this.getAttribute('href');
                    popup.classList.add('disclaimer--active')
                    popup.querySelector('.disclaimer__btn--yes').setAttribute('href', href);
                });
            })
        }
        popup.querySelector('.disclaimer__btn--no').addEventListener('click', e => {
            popup.classList.remove('disclaimer--active')
        })
        popup.querySelector('.disclaimer__btn--yes').addEventListener('click', e => {
            popup.classList.remove('disclaimer--active')
        })
    }
    
}

export {disclaimer}