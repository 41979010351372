const searchForm = () => {
    const mainForm = document.querySelector('.search-form-hidden');
    const form = document.querySelector('.search-form__wrapper');
    if(!form) return;

    const q_type = mainForm.querySelector('input[name="q_type"]');
    const sort = mainForm.querySelector('input[name="sort"]');
    const radio = form.querySelectorAll('.search-form__radio input');
    const filter = document.querySelector('.filter');
    const inputsFilter = document.querySelectorAll('.search-form-hidden .search-form-filter input');

    const customSelect = document.querySelector('.custom-select');

    const resetInput = () => {
        inputsFilter.forEach(element => {
            element.value = '';
            // console.log(element);
            // console.log(element.value);
        });
    }

    const change = (event) => {
        const value = event.target.dataset.name;
        event.target.classList.add('test');
        q_type.value = value;
    }


    const getDataOnFilter = (event) => {
        const data = new FormData(event.target);

        [...data.entries()].forEach(element => {
            // if (element[0] !== 'from' && element[0] !== 'to' ) {
                const currentInput = mainForm.querySelector(`input[name="${element[0]}"]`);

                if (currentInput) {
                    const params = currentInput.value.length > 0 ? `${currentInput.value},${element[1]}` : element[1];
                    currentInput.value = params.trim();
                }
            // }
        });
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        resetInput();

        if (filter) filter.dispatchEvent(new Event('get-data'));

        if (form) form.dispatchEvent(new Event('get-data'));

        mainForm.submit();

        // mainForm.dispatchEvent(new Event('submit'));
    }

    window.submitFilters = () => {
        if (filter) filter.dispatchEvent(new Event('submit'));

        if (form) form.dispatchEvent(new Event('submit'));
    }

    if (customSelect) {
        customSelect.addEventListener('change', (event) => {
            const target = event.target.value;
            sort.value = target;

            submitFilters();
        })
    }

    mainForm.addEventListener('submit', (event) => {
        event.preventDefault();
        const data = new FormData(event.target);

        [...data.entries()].forEach(element => {
            console.log(element);
        });
    });


    radio.forEach((element) => {
        element.addEventListener('change', change)
    });



    if (filter) {
        filter.addEventListener('get-data', getDataOnFilter);
        filter.addEventListener('submit', handleFormSubmit);
    }

    if (form) {
        form.addEventListener('submit', handleFormSubmit);
        form.addEventListener('get-data', getDataOnFilter);
    }


}

export {searchForm}

