/* eslint-disable no-unused-expressions */
import {removeClass} from './removeClass';

const tabs = () => {
	const item = document.querySelectorAll('.tabs__item');
	const products = document.querySelectorAll('.tabs__product');
	if (!item) {
		return false;
	}

	item.forEach((element) => {
		const cbx = element.querySelector('input');

		cbx.addEventListener('change', (event) => {
			const target = event.currentTarget;
			const parent = target.closest('.tabs__item');
			const name = parent.dataset.name;
			const product = document.querySelectorAll('.tabs__product');

			removeClass(item, 'active');
			removeClass(products, 'active');

			parent.classList.add('active');

			product.forEach((elem) => {
				const data = elem.dataset.name.split(',');
				const isAttr = data.some((att) => att.trim() === name);
				isAttr && elem.classList.add('active');
			});
		});

		if (cbx.checked === true) {
			let event = new CustomEvent('change');
			cbx.dispatchEvent(event);
		}
	});
};

export {tabs};
