const getParamsForFilter =() => {
    // console.log(getParam('q_type'));
    // const q_type = getParam('q_type') ? getParam('q_type') : 'all';
    // const checkedRadio = document.querySelector(`input[data-name="${q_type}"]`);


    // checkedRadio.checked=true;
    // checkedRadio.dispatchEvent(new Event('change'));

    const params = location.search.substring(1).split('&');

    if (params.length <= 1) return false

    let querys = {};

    const changeSelect = (type, val) => {
        const select = document.querySelectorAll(`.custom-select[name="${type}"] option`);

        if (select) {
            select.forEach(option => {
                if (option.value === val) {
                    option.selected = true;
                }
            });
        }
    }

    const checkRadio = (type, val) => {
        const input = document.querySelector(`.search-form__wrapper input[name="q-type"][data-name="${val}"]`);
        if (input && type === 'q_type') {
            input.checked=true;
            input.dispatchEvent(new Event('change'));
        }
    }

    const checkCheckboxs = (type, val) => {
        const input = document.querySelector(`.filter__section input[name="${type}"][value="${val}"]`);
        if (input) {
            input.checked=true;
            input.dispatchEvent(new Event('change'));
        }
    }

    const checkSearchVal = (type, val) => {
        const input = document.querySelector(`.search-form__wrapper input[name="${type}"]`);
        if (input) input.value=val;
    }

    params.forEach(pair => {
        const param = pair.split('=');
        const key = param[0];

        const value = param[1] && decodeURIComponent(param[1].replace('+', ' '));
        // value value = decodeURIComponent(pair.replace('+', ' ').split('=')[1]);
        // const checkedRadio = document.querySelector(`input[data-name="${key}"]`);

        // console.log(param);
        // console.log(key);
        // console.log(value);


        querys[key] = value.length > 0 && value.split(',')
    });

    console.log(querys, 'querys');


    Object.entries(querys).forEach(element => {
        const type = element[0];
        const value = element[1];

        if (value) {

            value.forEach(val => {

                checkCheckboxs(type, val);
                checkSearchVal(type, val);
                checkRadio(type, val);
                changeSelect(type, val);
                // console.log(`input[name="${type}"][value="${val}"]`);
                // const input = document.querySelector(`input[name="${type}"][value="${val}"]`);
                // console.log(input);

                // if (input) {
                //     console.log(input);
                //     console.log(input.type);
                //     // input.checked=true;
                //     // console.log(input);
                // }
            });
        }
    });

}

export {getParamsForFilter}
