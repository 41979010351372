const filterTabs = () => {
    const close = document.querySelector('.medical-library__close');
    const openFilter = document.querySelector('._open-filter-js');
    const tabs = document.querySelectorAll('.filter__title');


    const showFilters = () => {
        const filter = document.querySelector('.medical-library__filter');
        if (!filter.classList.contains('_active')) {
            filter.classList.add('_active');
        } else {
            filter.classList.remove('_active');
        }
    }

    openFilter.addEventListener('click', showFilters);
    close.addEventListener('click', showFilters);
    tabs.forEach((element) => {
        const list = element.closest('.filter__container').querySelector('.filter__list');
        $(list).hide(0);

        element.addEventListener('click', (event) => {
            const target = event.currentTarget
            const container = target.closest('.filter__container');
            const targetList = container.querySelector('.filter__list');

            if (!container.classList.contains('_show')) {
                container.classList.add('_show');
                $(targetList).show(250);
            } else {

                container.classList.remove('_show');
                $(targetList).hide(250);
            }
        })
    });

}

export {filterTabs}
