const slider = () => {
	const wrapper = $('.vacancies__slider--wrapper');
	wrapper.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		dots: false,
		variableWidth: true,
		centerMode: true,
		focusOnSelect: true,
		arrows: true,
		prevArrow: $('.vacancies__btn--prev'),
		nextArrow: $('.vacancies__btn--next'),
		responsive: [
			{
				breakpoint: 1023,
				settings: {
					slidesToShow: 1,
					variableWidth: false,
					centerMode: false,
					slidesToScroll: 1,
					// arrows: false,
					// dots: true,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					variableWidth: false,
					centerMode: false,
					slidesToScroll: 1,
					arrows: false,
					dots: true,
				},
			},
		],
	});
};

export {slider};
